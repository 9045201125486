import { FC, ReactNode } from 'react'
import { Switch as AntSwitch } from 'antd'
import clsx from 'clsx'

export interface Props {
  uncheckedLabel?: ReactNode | string
  checkedLabel?: ReactNode | string
  checkedColor?: string
  uncheckedColor?: string
  checked?: boolean
  className?: string
  dataCy?: string
  onChange?: (checked: boolean) => void
}

export const Switch: FC<Props> = ({
  checked,
  checkedLabel = '',
  uncheckedLabel = '',
  checkedColor = 'bg-qureosPrimary',
  uncheckedColor = 'bg-gray-300',
  className,
  dataCy,
  onChange
}) => {
  return (
    <AntSwitch
      data-testid="switch-input"
      data-cy={dataCy}
      className={clsx(className, {
        [checkedColor]: checked,
        [uncheckedColor]: !checked
      })}
      checked={checked}
      unCheckedChildren={uncheckedLabel}
      checkedChildren={checkedLabel}
      onChange={onChange}
    />
  )
}
